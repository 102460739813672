<template>
  <div id="serviceList">
    <div class="status-box">
      <div
        :class="statusIndex == index ? 'status-item active' : 'status-item'"
        v-for="(item, index) in statusArr"
        :key="index"
        @click="changeStatus(index)"
      >{{item}}
      </div>
    </div>

    <img class="nothing" src="@images/nothing.png" alt v-if="nothing"/>
    <p class="nothing-text" v-if="nothing">暂无相关订单</p>
    <div v-if="!nothing">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          class="list-box"
          v-model="loading"
          :finished="finished"
          finished-text="别划了，到底儿啦..."
          @load="getInfo"
        >
          <div
            class="list-item"
            v-for="(item, index) in ordersList"
            :key="index"
            @click="goDetail(item.id)"
          >
            <div class="top-info">
              <div>
                <span class="info-name">{{item.contacts}}</span>
                <span class="info-address">{{item.address}}</span>
              </div>
              <p v-if="item.refund_status == 0">待退款</p>
              <p v-if="item.refund_status == 2">已拒绝</p>
              <p v-if="item.refund_status == 1">已同意</p>
            </div>

            <div class="center-info">
              <swiper :options="swiperOption" class="myswiper">
                <swiper-slide v-for="(items, indexs) in item.product_order_info" :key="indexs">
                  <img v-lazy="items.product_cover" alt/>
                </swiper-slide>
              </swiper>

              <div class="data-box">
                <div class="data-code">
                  <img src="@images/phone.png" alt/>
                  <a :href="'tel://' + item.telephone">{{item.telephone}}</a>
                </div>

                <div class="data-num">
                  <span>共{{item.product_num}}件</span>
                  <p>实付：</p>
                  <i>￥{{item.total_amount | fixdTwo}}</i>
                </div>
              </div>
            </div>

            <div class="bottom-info">
              <div class="green-btn info-btn">查看详情</div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
  // import { Toast } from "vant";
  import {refundList} from "../api/requist";

  export default {
    components: {},
    filters: {
      fixdTwo: function (val) {
        return Math.round(val * 100) / 100
      }
    },
    props: [],
    data() {
      return {
        nothing: false,
        pageSize: 5,
        pageCurrent: 1,
        loading: false,
        finished: false,
        refreshing: false,
        statusArr: ["全部", "待退款", "已拒绝", "已同意"],
        statusIndex: 0,
        ordersList: [],
        title: "退款/售后",
        swiperOption: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 4,
          observer: true, // 修改swiper自己或子元素时，自动初始化swiper
          observeParents: true // 修改swiper的父元素时，自动初始化swiper
        }
      };
    },
    created() {
    },
    mounted() {
    },
    watch: {},
    computed: {},
    methods: {
      onRefresh() {
        // 清空列表数据
        this.finished = false;

        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        this.loading = true;
        this.getInfo();
      },
      async getInfo() {
        //       -1：全部
        // 0：待审核
        // 1：已同意
        // 2：已拒绝
        // if (change) {
        //   this.pageCurrent = 1;
        //   this.ordersList = [];
        //   this.loading = false;
        //   this.finished = false;
        //   this.refreshing = false;
        // }

        let refund_status;
        if (this.statusIndex == 0) {
          refund_status = -1;
        } else if (this.statusIndex == 1) {
          refund_status = 0;
        } else if (this.statusIndex == 2) {
          refund_status = 2;
        } else if (this.statusIndex == 3) {
          refund_status = 1;
        }

        if (this.refreshing) {
          this.pageCurrent = 1;
          this.ordersList = [];
          this.refreshing = false;
        }
        let formData = new FormData();
        formData.append("merch_id", localStorage.getItem("merch_id"));
        formData.append("refund_status", refund_status);
        formData.append("pageSize", this.pageSize);
        formData.append("pageCurrent", this.pageCurrent);
        let res = await refundList(formData);
        if (res.code === 200) {
          this.ordersList.push(...res.data.OrdersList);
          if (res.data.OrdersList.length != 0) {
            this.pageCurrent = ++this.pageCurrent;
          } else {
            this.finished = true;
          }
          this.loading = false;
        } else {
          this.finished = true;
        }
        if (this.ordersList.length == 0) {
          this.nothing = true;
        } else {
          this.nothing = false;
        }
      },
      changeStatus(index) {
        this.pageCurrent = 1;
        this.ordersList = [];
        this.statusIndex = index;
        this.getInfo();
      },

      goDetail(id) {
        this.$router.push({
          path: "/serviceDetail",
          query: {
            id
          }
        });
      }
    }
  };
</script>
<style lang="scss">
  @import "../common/css/theme.scss";

  #serviceList {
    min-height: 100vh;
    background: rgba(245, 247, 250, 1);

    .van-pull-refresh {
      min-height: 100vh;
      padding-top: 9.6* 3.75px;
      box-sizing: border-box;
    }

    .nothing {
      height: 66.18* 3.75px;
      width: 100%;
      margin-top: 48.53* 3.75px;
    }

    .nothing-text {
      color: rgba(96, 98, 102, 1);
      font-size: 3.73* 3.75px;
      text-align: center;
      margin-top: 3.47* 3.75px;
    }

    .status-box {
      z-index: 999;
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 1);
      padding: 0 4* 3.75px;
      box-sizing: border-box;
      height: 9.6* 3.75px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .status-item {
        line-height: 9.6* 3.75px;
        height: 100%;
        width: 18.4* 3.75px;
        font-size: 3.73* 3.75px;
        box-sizing: border-box;
        color: rgba(48, 49, 51, 1);
        text-align: center;

        &.active {
          border-bottom: 2px solid $theme-color;
        }
      }
    }

    .list-box {
      margin-top: 2.67* 3.75px;

      .list-item {
        position: relative;
        padding: 0 2.13* 3.75px;
        box-sizing: border-box;
        margin: 0 12px 12px 12px;
        height: 56* 3.75px;
        background: rgba(255, 255, 255, 1);
        opacity: 1;
        border-radius: 1* 3.75px;

        &:last-child {
          margin-bottom: 0;
        }

        .top-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 10.93* 3.75px;
          border-bottom: 1px solid rgba(240, 242, 245, 1);

          div {
            display: flex;
            align-items: center;
          }

          .info-name {
            margin-right: 2.13* 3.75px;
          }

          .info-address {
          }

          span {
            color: #303133;
            font-size: 3.2* 3.75px;
          }

          p {
            color: $theme-color;
            font-size: 3.2* 3.75px;
          }
        }

        .center-info {
          padding-top: 3.2* 3.75px;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(240, 242, 245, 1);
          height: 32.27* 3.75px;

          .myswiper {
            margin-bottom: 2.13* 3.75px;
            height: 17.6* 3.75px;

            .swiper-slide {
              height: 17.6* 3.75px;
              width: 17.6* 3.75px;

              img {
                height: 17.6* 3.75px;
                width: 17.6* 3.75px;
              }
            }
          }

          .data-box {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .data-code {
              width: 29.07* 3.75px;
              height: 7.73* 3.75px;
              background: rgba(245, 247, 250, 1);
              border-radius: 4* 3.75px;
              display: flex;
              align-items: center;
              padding: 0 1.87* 3.75px;
              box-sizing: border-box;

              img {
                width: 3.2* 3.75px;
                margin-right: 1.07* 3.75px;
                height: 3.2* 3.75px;
              }

              a {
                color: rgba(0, 0, 0, 1);
                font-size: 3.2* 3.75px;
              }
            }

            .data-num {
              margin-right: 1.34* 3.75px;
              display: flex;
              align-items: center;

              span {
                color: rgba(48, 49, 51, 1);
                font-size: 3.2* 3.75px;
                margin-right: 1.07* 3.75px;
              }

              p {
                color: rgba(48, 49, 51, 1);
                font-size: 3.2* 3.75px;
              }

              i {
                font-style: normal;
                font-weight: bold;
                color: rgba(255, 0, 0, 1);
                font-size: 4.27* 3.75px;
              }
            }
          }
        }

        .bottom-info {
          height: 12.8* 3.75px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 0 0.8* 3.75px;
          box-sizing: border-box;

          .info-btn {
            &:last-child {
              margin-left: 3.2* 3.75px;
            }
          }

          .green-btn {
            text-align: center;
            padding: 4px 8px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid $theme-color;
            border-radius: 1* 3.75px;
            font-size: 12px;
            line-height: 12*1.4px;
            color: $theme-color;
          }

          .black-btn {
            text-align: center;
            padding: 4px 8px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(96, 98, 102, 1);
            border-radius: 1* 3.75px;
            font-size: 12px;
            line-height: 12*1.4px;
            color: #606266;
          }
        }
      }
    }

    .out-tips {
      color: rgba(192, 196, 204, 1);
      font-size: 3.2* 3.75px;
      text-align: center;
      padding: 5.33* 3.75px 0 4* 3.75px;
    }
  }
</style>
